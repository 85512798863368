html {
  color-scheme: light;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-family: system-ui;
  display: flex;
}

h1 {
  color: #393939;
  text-align: center;
  text-shadow: 0 -.025em .1em #000;
}

h1 span {
  margin: 0 1vmin;
}

h1 b {
  background: #fafafa;
  border-radius: .25rem;
  margin: 0 .5vmin;
  padding: .05em .1em;
  box-shadow: 0 10px 20px #0006;
}
/*# sourceMappingURL=repo.5063c61e.css.map */
