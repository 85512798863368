html {
  color-scheme: light;
  font-family: system-ui;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

h1 {
  color: rgb(57, 57, 57);
  text-align: center;
  text-shadow: 0 -0.025em 0.1em rgba(0, 0, 0, 1);
}

h1 span {
  margin: 0 1vmin;
}

h1 b {
  margin: 0 0.5vmin;
  border-radius: 0.25rem;
  background: rgb(250, 250, 250);
  padding: 0.05em 0.1em;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
}
